import { RefObject, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Input, Toast, Typography } from '@wbk/ui';
import { useTranslation } from 'react-i18next';
import { useRegisterCompetition } from '@wbk/api/hooks';
import { AnimatedCheck } from '@wbk/svg';
import { WithGrecaptcha } from '@wbk/api/grecaptcha';
import { COUNTRIES } from '@wbk/utils';
import { useParams } from 'react-router-dom';
import { reduceImageSize } from '@/utils/reduceImageSize';

type FormValues = {
  name: string;
  phone: string;
  photo: File;
  national_id: string;
};

const saCountry = COUNTRIES.find((country) => country.code === 'SA');
const MAX_IMAGE_SIZE = 2000 * 1024; // 2MB

const CompetitionPage = () => {
  const { lang } = useParams();
  const { t } = useTranslation();
  const toastRef = useRef<React.ComponentRef<typeof Toast>>(null);
  const [success, setSuccess] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const { mutateAsync, isPending, error } = useRegisterCompetition();

  const { handleSubmit, control } = useForm<FormValues>({
    defaultValues: {
      name: '',
      phone: '',
      national_id: '',
    },
  });

  const fieldErrors = error?.errors;

  const [imagePreview, setImagePreview] = useState<string | null>(null);

  const onSubmit = async (data: FormValues) => {
    try {
      const formData = new FormData();
      const reducedPhoto = await reduceImageSize(data.photo, MAX_IMAGE_SIZE);
      formData.append('name', data.name);
      formData.append('phone', `966${data.phone}`);
      formData.append('photo', reducedPhoto);
      formData.append('national_id', data.national_id);
      formData.append('lang', lang || 'en');
      await mutateAsync(formData);
      setSuccess(true);
    } catch (err) {
      toastRef.current?.publish({
        type: 'error',
        title: (err as Error).message || t('common:competition.failed'),
      });
    }
  };

  return (
    <WithGrecaptcha>
      <Toast ref={toastRef} />
      <div className='container'>
        <div className='border-paper mx-auto my-44 w-full max-w-md rounded-lg border px-4 py-8 lg:px-6'>
          {success ? (
            <div className='space-y-1 text-center'>
              <AnimatedCheck className='inline-block h-24 w-24' />
              <Typography as='h2' variant='heading-M'>
                {t('common:competition.success')}
              </Typography>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)} className='space-y-4'>
              <Typography variant='heading-L' as='h1'>
                {t('common:competition.title')}
              </Typography>
              <Controller
                control={control}
                name='photo'
                rules={{ required: t('common:validation.required') }}
                render={({ field, fieldState: { error } }) => {
                  const photoError = fieldErrors?.photo?.[0] || error?.message;

                  return (
                    <div className='flex flex-col items-center'>
                      <Typography
                        variant='label-M'
                        as='label'
                        htmlFor='photo'
                        className='self-start font-semibold'
                      >
                        {t('common:competition.take_your_photo')}
                      </Typography>

                      <Button
                        type='button'
                        theme='transparent'
                        className='border-paper aria-[invalid=true]:border-error group relative flex h-24 w-24 cursor-pointer items-center justify-center overflow-hidden rounded-full border-2'
                        onClick={() => fileInputRef.current?.click()}
                        aria-invalid={!!photoError}
                        id='photo'
                        ref={field.ref as unknown as RefObject<HTMLButtonElement>}
                      >
                        {imagePreview && (
                          <img
                            src={imagePreview}
                            alt='Preview'
                            className='absolute inset-0 h-full w-full rounded-full object-cover'
                          />
                        )}
                        <input
                          type='file'
                          accept='image/*'
                          hidden
                          capture='user'
                          className='absolute inset-0 cursor-pointer opacity-0'
                          ref={fileInputRef}
                          onChange={(e) => {
                            if (e.target.files && e.target.files[0]) {
                              const file = e.target.files[0];
                              field.onChange(file);
                              setImagePreview(URL.createObjectURL(file));
                            }
                          }}
                        />

                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='text-text-secondary data-[preview=true]:text-body-dark z-10 h-10 w-10 data-[preview=true]:opacity-0 data-[preview=true]:transition-opacity data-[preview=true]:group-hover:opacity-100'
                          data-preview={!!imagePreview}
                          width='800px'
                          height='800px'
                          viewBox='0 0 24 24'
                        >
                          <path
                            fill='currentColor'
                            fillRule='evenodd'
                            clipRule='evenodd'
                            d='M9.77778 21H14.2222C17.3433 21 18.9038 21 20.0248 20.2646C20.51 19.9462 20.9267 19.5371 21.251 19.0607C22 17.9601 22 16.4279 22 13.3636C22 10.2994 22 8.76721 21.251 7.6666C20.9267 7.19014 20.51 6.78104 20.0248 6.46268C19.3044 5.99013 18.4027 5.82123 17.022 5.76086C16.3631 5.76086 15.7959 5.27068 15.6667 4.63636C15.4728 3.68489 14.6219 3 13.6337 3H10.3663C9.37805 3 8.52715 3.68489 8.33333 4.63636C8.20412 5.27068 7.63685 5.76086 6.978 5.76086C5.59733 5.82123 4.69555 5.99013 3.97524 6.46268C3.48995 6.78104 3.07328 7.19014 2.74902 7.6666C2 8.76721 2 10.2994 2 13.3636C2 16.4279 2 17.9601 2.74902 19.0607C3.07328 19.5371 3.48995 19.9462 3.97524 20.2646C5.09624 21 6.65675 21 9.77778 21ZM12 9.27273C9.69881 9.27273 7.83333 11.1043 7.83333 13.3636C7.83333 15.623 9.69881 17.4545 12 17.4545C14.3012 17.4545 16.1667 15.623 16.1667 13.3636C16.1667 11.1043 14.3012 9.27273 12 9.27273ZM12 10.9091C10.6193 10.9091 9.5 12.008 9.5 13.3636C9.5 14.7192 10.6193 15.8182 12 15.8182C13.3807 15.8182 14.5 14.7192 14.5 13.3636C14.5 12.008 13.3807 10.9091 12 10.9091ZM16.7222 10.0909C16.7222 9.63904 17.0953 9.27273 17.5556 9.27273H18.6667C19.1269 9.27273 19.5 9.63904 19.5 10.0909C19.5 10.5428 19.1269 10.9091 18.6667 10.9091H17.5556C17.0953 10.9091 16.7222 10.5428 16.7222 10.0909Z'
                          />
                        </svg>
                      </Button>

                      {photoError && (
                        <Typography color='error' variant='body-XS'>
                          {photoError}
                        </Typography>
                      )}
                    </div>
                  );
                }}
              />

              <Controller
                control={control}
                name='name'
                rules={{ required: t('common:validation.required') }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <Input
                      label={t('common:competition.full_name')}
                      error={fieldErrors?.name?.[0] || error?.message}
                      id='name'
                      {...field}
                    />
                  );
                }}
              />

              <Controller
                control={control}
                name='national_id'
                rules={{
                  required: t('common:validation.required'),
                  validate: (value) =>
                    String(value).length === 10 ? true : t('common:validation.invalid_national_id'),
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <Input
                      type='number'
                      className='[&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none'
                      securityLimit={10}
                      label={t('common:competition.national_id')}
                      error={fieldErrors?.national_id?.[0] || error?.message}
                      id='national_id'
                      {...field}
                    />
                  );
                }}
              />

              <Controller
                control={control}
                name='phone'
                rules={{
                  required: t('common:validation.required'),
                  validate: (value) => {
                    return saCountry?.mobile_starts_with?.some((prefix) =>
                      value.startsWith(prefix)
                    ) && saCountry?.phone_number_lengths?.includes(value.length)
                      ? true
                      : t('common:validation.invalid_phone');
                  },
                }}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <div className='space-y-2'>
                      <Typography
                        variant='label-M'
                        as='label'
                        htmlFor='phone'
                        className='font-semibold'
                      >
                        {t('common:competition.mobile')}
                      </Typography>

                      <div className='flex items-start' dir='ltr'>
                        <div
                          className='bg-input border-input-border text-text-secondary flex h-10 shrink-0 items-center justify-between gap-2 rounded-md rounded-r-none border border-r-0 p-2'
                          dir='ltr'
                        >
                          <img
                            className='shrink-0'
                            src='https://wbk-assets.s3.me-south-1.amazonaws.com/flags/w80/sa.png'
                            height={14}
                            width={20}
                            alt='Saudi Arabia'
                          />
                          <div dir='ltr' className='font-sans'>
                            +966
                          </div>
                        </div>

                        <Input
                          {...field}
                          minLength={9}
                          maxLength={10}
                          type='tel'
                          error={fieldErrors?.phone?.[0] || error?.message}
                          groupClassName='grow'
                          className='h-10 rounded-l-none font-sans'
                          dir='ltr'
                          id='phone'
                        />
                      </div>
                    </div>
                  );
                }}
              />

              <Button type='submit' className='w-full' loading={isPending}>
                {t('common:competition.submit')}
              </Button>
            </form>
          )}
        </div>
      </div>
    </WithGrecaptcha>
  );
};

export default CompetitionPage;
