import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createCookie } from '@wbk/utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { defaultLang, shortLangsMap, supportedLngs } from '@/i18n/config';
import Context from './context';

type Props = {
  children: React.ReactNode;
};

const LocalizationProvider = ({ children }: Props) => {
  const { i18n } = useTranslation('common');
  const { lang } = useParams<{ lang: Language }>();
  const { pathname } = useLocation();
  const validLang = lang && supportedLngs.includes(lang) ? lang : defaultLang;
  const shortLang = shortLangsMap[validLang] || 'en';
  const navigate = useNavigate();

  useEffect(() => {
    if (shortLang) {
      setLocale(shortLang);
    }
    // eslint-disable-next-line
  }, []);

  const setLocale = async (language: string) => {
    const pathWithoutLang = pathname.replace(`/${lang}`, '');
    await i18n.changeLanguage(language);
    createCookie({ name: 'lang', value: language, expiration: 30 });
    document.documentElement.lang = language;
    document.documentElement.dir = language.startsWith('ar') ? 'rtl' : 'ltr';

    const search = new URLSearchParams(window.location.search).toString();
    const dest = `/${language}${pathWithoutLang}${search ? `?${search}` : ''}`;
    navigate(dest);
  };

  return (
    <Context.Provider
      value={{
        locale: shortLang,
        setLocale,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default LocalizationProvider;
