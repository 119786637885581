import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

export const languages: Language[] = ['en', 'ar'];
export const supportedLngs = languages.concat('en-US', 'ar-SA', 'en-us', 'ar-sa');
export const defaultLang = languages[0];
export const shortLangsMap: Record<(typeof supportedLngs)[number], string> = {
  'en-US': 'en',
  'en-us': 'en',
  'ar-SA': 'ar',
  'ar-sa': 'ar',
  en: 'en',
  ar: 'ar',
};

i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // lng: 'en', // if you're using a language detector, do not define the lng option
    // debug: true,
    supportedLngs,
    fallbackLng: defaultLang,
    fallbackNS: 'common',
    defaultNS: 'common',
    nonExplicitSupportedLngs: true, // Handle both en-US and en
    // ns: ['common', 'event', 'booking', 'contact', 'home', 'profile', 'seats', 'business', 'formula'],

    // if you see an error like: "Argument of type 'DefaultTFuncReturn' is not assignable to parameter of type xyz"
    // set returnNull to false (and also in the i18next.d.ts options)
    // returnNull: false,

    // disable cache
    backend: {
      requestOptions: {
        cache: 'no-store',
      },
    },
  });

export default i18next;
